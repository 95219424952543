<script setup lang="ts">
  import IconSettings from '@/assets/icons/Settings.svg'
  import { useCurrentCompany } from '@/modules/base/composable/useCurrentCompany'
  import { Route } from '@/modules/base/config/routesConfig'
  import MenuLink from './MenuLink.vue'

  const { currentCompanyId } = useCurrentCompany()
</script>

<template>
  <MenuLink
    data-testid="the-menu-link-settings"
    match-exact
    :to="{ name: Route.SettingsDefault }"
  >
    <template #icon>
      <IconSettings />
    </template>
  </MenuLink>
  <MenuLink
    v-if="
      // TODO: Make User a valid CASL subject and/or use feature flag subject https://app.clickup.com/t/86c0njxtf
      // @ts-expect-error
      $can(['index', 'show', 'create', 'update'], {
        __typename: 'User',
        companyId: currentCompanyId,
      })
    "
    data-testid="the-menu-link-settings-users"
    submenu
    :to="{ name: Route.SettingsUsers }"
  />
  <MenuLink
    data-testid="the-menu-link-settings-locations"
    submenu
    :to="{ name: Route.SettingsLocations }"
  />
  <MenuLink
    data-testid="the-menu-link-settings-organization"
    submenu
    :to="{ name: Route.SettingsOrganization }"
  />
  <MenuLink
    data-testid="the-menu-link-settings-my-account"
    submenu
    :to="{ name: Route.SettingsMyAccount }"
  />
  <MenuLink
    data-testid="the-menu-link-audit"
    submenu
    :to="{ name: Route.Audit }"
  />
</template>
