<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { Feature } from '@/api/useCompany.types'
  import { useAccess } from '@/modules/base/composable/useAccess'
  import { useLayoutStore } from '@/modules/base/stores/useLayoutStore'
  import TheMenuDashboard from './TheMenuDashboard.vue'
  import TheMenuFaq from './TheMenuFaq.vue'
  import TheMenuFootprint from './TheMenuFootprint.vue'
  import TheMenuReduction from './TheMenuReduction.vue'
  import TheMenuReporting from './TheMenuReporting.vue'
  import TheMenuSettings from './TheMenuSettings.vue'

  const layout = useLayoutStore()
  const isOpen = computed(() => layout.isMenuOpen)

  const { can } = useAccess()

  const menu = ref<HTMLElement | null>(null)

  watch(isOpen, (_isOpen) => {
    const setOpenState = () => {
      if (_isOpen) {
        menu.value?.classList.remove('hidden')
      } else {
        menu.value?.classList.add('hidden')
      }
    }

    const isMotionSafe = window.matchMedia(
      '(prefers-reduced-motion: no-preference)'
    ).matches

    if (isMotionSafe && document.startViewTransition) {
      document.startViewTransition(setOpenState)
    } else {
      setOpenState()
    }
  })
</script>

<template>
  <div ref="menu" class="gc-menu bg-gray-var-900 z-30 flex min-w-56">
    <nav
      id="the-menu"
      class="gc-global-scrollbar gc-global-scrollbar-thin gc-global-scrollbar-white-var-30 gutter-stable m-1 max-h-screen w-max overflow-y-auto px-6 pb-14"
      data-testid="the-menu"
    >
      <p class="sr-only">
        {{ $t('global.menu.title') }}
      </p>
      <div class="grid grid-cols-[1.4rem,1fr] gap-x-2">
        <template v-if="can(Feature.Reduction)">
          <TheMenuDashboard />
          <TheMenuReduction />
        </template>
        <template v-if="can(Feature.Footprint)">
          <TheMenuFootprint />
        </template>
        <template v-if="can(Feature.Reporting)">
          <TheMenuReporting />
        </template>
        <TheMenuSettings />
        <TheMenuFaq />
      </div>
    </nav>
  </div>
</template>

<style scoped>
  .gc-menu {
    view-transition-name: menu;
  }
</style>

<style>
  .gc-global-main-view-transition-name {
    view-transition-name: main;
  }

  .gc-global-base-container-transition-name {
    view-transition-name: base-container;
  }

  @keyframes translate-x {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }

  ::view-transition-old(root),
  ::view-transition-new(root) {
    animation-duration: 0.25s;
  }

  ::view-transition-old(main),
  ::view-transition-new(main) {
    animation-duration: 0.25s;
    animation-timing-function: ease-in-out;

    @apply h-full;
  }

  ::view-transition-old(base-container),
  ::view-transition-new(base-container) {
    animation-duration: 0.25s;
    animation-timing-function: ease-in-out;

    @apply h-full;
  }

  ::view-transition-old(menu) {
    animation: 0.25s ease-in-out both translate-x;
  }

  ::view-transition-new(menu) {
    animation: 0.25s ease-in-out both reverse translate-x;
  }
</style>
