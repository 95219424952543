<script setup lang="ts">
  import useVuelidate from '@vuelidate/core'
  import { reactive } from 'vue'
  import { useMutationCompanyCreate } from '@/api/useCompany'
  import BaseInput from '@/modules/base/components/BaseInput.vue'
  import CountrySelect from '@/modules/settings/components/CountrySelect.vue'
  import { useI18nValidators } from '@/modules/shared/utils/i18n-validators'

  const props = defineProps<{
    parentCompanyId: number
  }>()

  const emit = defineEmits<{
    close: []
  }>()

  const form = reactive<{
    name: string
    country: string
  }>({
    name: '',
    country: '',
  })

  const { required } = useI18nValidators()
  const v$ = useVuelidate()

  const { mutateAsync: createCompany, isPending } = useMutationCompanyCreate()
  const createCompanyAndClose = async () => {
    if (!(await v$.value.$validate())) {
      return
    }

    await createCompany({
      mainLocationAttributes: {
        name: form.name,
        country: form.country,
      },
      parentId: props.parentCompanyId,
    })

    emit('close')
  }
</script>

<template>
  <div class="flex flex-col gap-7">
    <h3 class="text-2xl">{{ $t('global.header.createCompanyModal.title') }}</h3>
    <div class="mb-8 flex flex-col gap-4">
      <BaseInput
        v-model="form.name"
        v-tooltip="{
          content: $t('global.settings.form.locationNameTooltip'),
          options: { placement: 'right' },
        }"
        :label="$t('global.header.createCompanyModal.companyName')"
        name="name"
        :placeholder="$t('global.header.createCompanyModal.namePlaceholder')"
        :validation="{ required }"
      />
      <CountrySelect
        v-model="form.country"
        :label="$t(`global.header.createCompanyModal.country`)"
        required
      />
    </div>
    <div class="flex flex-col gap-4">
      <BaseButton
        class="self-center"
        :class="{
          '!cursor-progress': isPending,
        }"
        :disabled="isPending"
        primary
        @click="createCompanyAndClose"
      >
        {{ $t('global.header.createCompanyModal.createButton') }}
      </BaseButton>
      <BaseButton
        class="self-center"
        :class="{
          '!cursor-progress': isPending,
        }"
        :disabled="isPending"
        secondary
        @click="$emit('close')"
      >
        {{ $t('global.modalConfirmation.labelCancel') }}
      </BaseButton>
    </div>
  </div>
</template>
