<script setup lang="ts">
  import { computed } from 'vue'
  import IconLogoBlackText from '@/assets/icons/LogoBlackText.svg?url'
  import IconLogoWhiteText from '@/assets/icons/LogoWhiteText.svg?url'
  import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'

  defineProps<{
    square: boolean
  }>()

  const { currentUser } = useCurrentUser()

  const logoThemed = computed(() => {
    return currentUser.value?.useLightTheme
      ? IconLogoBlackText
      : IconLogoWhiteText
  })
</script>

<template>
  <img v-show="!square" data-testid="app-logo-with-text" :src="logoThemed" />
  <img
    v-show="square"
    data-testid="app-logo-square"
    src="@/assets/icons/LogoSquare.svg?noTransform"
  />
</template>
